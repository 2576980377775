import {
    isEqualTrackRef,
    isTrackReference,
    isWeb,
    log,
} from "@livekit/components-core";
import { RemoteParticipant, RoomEvent, Track } from "livekit-client";
import * as React from "react";
import {
    CarouselLayout,
    ConnectionStateToast,
    ControlBar,
    FocusLayout,
    FocusLayoutContainer,
    GridLayout,
    LayoutContextProvider,
    ParticipantTile,
    RoomAudioRenderer,
    useCreateLayoutContext,
    useLocalParticipant,
    usePinnedTracks,
    useRoomContext,
    useTracks,
} from "@livekit/components-react";
import { BackgroundBlur, VirtualBackground } from "@livekit/track-processors";

/**
 * @public
 */

/**
 * The `VideoConference` ready-made component is your drop-in solution for a classic video conferencing application.
 * It provides functionality such as focusing on one participant, grid view with pagination to handle large numbers
 * of participants, basic non-persistent chat, screen sharing, and more.
 *
 * @remarks
 * The component is implemented with other LiveKit components like `FocusContextProvider`,
 * `GridLayout`, `ControlBar`, `FocusLayoutContainer` and `FocusLayout`.
 * You can use these components as a starting point for your own custom video conferencing application.
 *
 * @example
 * ```tsx
 * <LiveKitRoom>
 *   <VideoConference />
 * <LiveKitRoom>
 * ```
 * @public
 */
export function VideoConference({
    chatMessageFormatter,
    chatMessageDecoder,
    chatMessageEncoder,
    SettingsComponent,
    background,
    ...props
}) {
    const [widgetState, setWidgetState] = React.useState({
        showChat: false,
        unreadMessages: 0,
        showSettings: false,
    });
    const lastAutoFocusedScreenShareTrack = React.useRef(null);
    const disconnectTimer = React.useRef();
    const [remoteParticipantCount, setRemoteParticipantCount] =
        React.useState(0);

    const room = useRoomContext();

    React.useEffect(() => {
        if (!room) {
            return;
        }
        disconnectTimer.current = window.setTimeout(() => {
            if (room.remoteParticipants.size == 0) {
                console.log("Disconnecting Timeout");
                room.disconnect();
            }
        }, 30000);

        return () => {
            if (disconnectTimer.current)
                window.clearTimeout(disconnectTimer.current);
        };
    }, [room]);

    const tracks = useTracks(
        [
            { source: Track.Source.Camera, withPlaceholder: true },
            { source: Track.Source.ScreenShare, withPlaceholder: false },
        ],
        {
            updateOnlyOn: [RoomEvent.ActiveSpeakersChanged],
            onlySubscribed: false,
        }
    );

    const widgetUpdate = (state) => {
        log.debug("updating widget state", state);
        setWidgetState(state);
    };

    const layoutContext = useCreateLayoutContext();

    const screenShareTracks = tracks
        .filter(isTrackReference)
        .filter(
            (track) => track.publication.source === Track.Source.ScreenShare
        );

    const focusTrack = usePinnedTracks(layoutContext)?.[0];
    const carouselTracks = tracks.filter(
        (track) => !isEqualTrackRef(track, focusTrack)
    );

    const { cameraTrack } = useLocalParticipant();

    React.useEffect(() => {
        // If screen share tracks are published, and no pin is set explicitly, auto set the screen share.
        if (
            screenShareTracks.some((track) => track.publication.isSubscribed) &&
            lastAutoFocusedScreenShareTrack.current === null
        ) {
            log.debug("Auto set screen share focus:", {
                newScreenShareTrack: screenShareTracks[0],
            });
            layoutContext.pin.dispatch?.({
                msg: "set_pin",
                trackReference: screenShareTracks[0],
            });
            lastAutoFocusedScreenShareTrack.current = screenShareTracks[0];
        } else if (
            lastAutoFocusedScreenShareTrack.current &&
            !screenShareTracks.some(
                (track) =>
                    track.publication.trackSid ===
                    lastAutoFocusedScreenShareTrack.current?.publication
                        ?.trackSid
            )
        ) {
            log.debug("Auto clearing screen share focus.");
            layoutContext.pin.dispatch?.({ msg: "clear_pin" });
            lastAutoFocusedScreenShareTrack.current = null;
        }
        if (focusTrack && !isTrackReference(focusTrack)) {
            const updatedFocusTrack = tracks.find(
                (tr) =>
                    tr.participant.identity ===
                        focusTrack.participant.identity &&
                    tr.source === focusTrack.source
            );
            if (
                updatedFocusTrack !== focusTrack &&
                isTrackReference(updatedFocusTrack)
            ) {
                layoutContext.pin.dispatch?.({
                    msg: "set_pin",
                    trackReference: updatedFocusTrack,
                });
            }
        }
    }, [
        screenShareTracks
            .map(
                (ref) =>
                    `${ref.publication.trackSid}_${ref.publication.isSubscribed}`
            )
            .join(),
        focusTrack?.publication?.trackSid,
        tracks,
    ]);

    React.useEffect(() => {
        const localCamTrack = cameraTrack?.track;
        if (localCamTrack && background !== null) {
            let blur = null;
            if (background == "blur") {
                blur = BackgroundBlur("8px");
            } else {
                blur = VirtualBackground(background);
            }
            if (blur) {
                try {
                    localCamTrack.stopProcessor();
                    localCamTrack.setProcessor(blur);
                } catch (error) {}
            }
        }
    }, [cameraTrack, background]);

    // useWarnAboutMissingStyles();

    return (
        <div className="lk-video-conference" {...props}>
            {isWeb() && (
                <LayoutContextProvider
                    value={layoutContext}
                    // onPinChange={handleFocusStateChange}
                    onWidgetChange={widgetUpdate}
                >
                    <div className="lk-video-conference-inner">
                        {!focusTrack ? (
                            <div className="lk-grid-layout-wrapper">
                                <GridLayout tracks={tracks}>
                                    <ParticipantTile />
                                </GridLayout>
                            </div>
                        ) : (
                            <div className="lk-focus-layout-wrapper">
                                <FocusLayoutContainer>
                                    <CarouselLayout tracks={carouselTracks}>
                                        <ParticipantTile />
                                    </CarouselLayout>
                                    {focusTrack && (
                                        <FocusLayout trackRef={focusTrack} />
                                    )}
                                </FocusLayoutContainer>
                            </div>
                        )}
                        <ControlBar
                            controls={{
                                chat: true,
                                settings: !!SettingsComponent,
                            }}
                        />
                    </div>
                    {SettingsComponent && (
                        <div
                            className="lk-settings-menu-modal"
                            style={{
                                display: widgetState.showSettings
                                    ? "block"
                                    : "none",
                            }}
                        >
                            <SettingsComponent />
                        </div>
                    )}
                </LayoutContextProvider>
            )}
            <RoomAudioRenderer />
            <ConnectionStateToast />
        </div>
    );
}
