import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom/client";
import {
    GridLayout,
    LiveKitRoom,
    ParticipantTile,
    TrackRefContext,
    useLocalParticipant,
    useRoomContext,
    useTracks,
} from "@livekit/components-react";
import "@livekit/components-styles";
import { Track } from "livekit-client";
import { BackgroundBlur, VirtualBackground } from "@livekit/track-processors";
import { VideoConference } from "./VideoConference";
import * as Sentry from "@sentry/react";

function Room() {
    const [liveKitToken, setLiveKitToken] = useState(null);
    const [serverUrl, setServerUrl] = useState(null);
    const [roomStatusText, setRoomStatusText] = useState("Loading...");
    const [background, setBackground] = useState(null);
    const [callConnected, setCallConnected] = useState(false);
    const [callID, setCallID] = useState(null);
    const hasRun = useRef(false);

    useEffect(() => {
        if (!hasRun.current) {
            hasRun.current = true;
            // Get `call_id` from URL
            const call_id = new URLSearchParams(window.location.search).get(
                "id"
            );
            const mode = new URLSearchParams(window.location.search).get(
                "mode"
            );
            // Get `token` from <meta> tag
            const token = document.querySelector(
                'meta[name="agent-token"]'
            )?.content;
            const popinServer = document.querySelector(
                'meta[name="popin-server"]'
            )?.content;

            window.popinLog("room_init", {
                connectRequest: call_id,
                mode: mode,
            });

            if (call_id && token) {
                // Fetch LiveKit token and server URL
                const requestURL =
                    mode == 1 ? `/connection/accept` : `/participant/accept`;
                const requestBody =
                    mode == 1
                        ? { connect_request_component_id: call_id }
                        : { call_participant_id: call_id };

                window.axios
                    .create({
                        baseURL: popinServer + `/api/v1/seller`,
                        // timeout: 2000,
                        headers: { Authorization: `Bearer ${token}` },
                    })
                    .post(requestURL, requestBody)
                    .then(({ data }) => {
                        console.log("Call Data");
                        console.log(data);
                        if (data.status == 1) {
                            window.popinLog("room_call_connected", {
                                connectRequest: call_id,
                                mode: mode,
                                userID: data.user_id,
                                callID: data.id,
                            });

                            setCallConnected(true);

                            setLiveKitToken(data.access_token); // Set token for LiveKitRoom
                            setServerUrl(data.websocket); // Set server URL
                            Livewire.dispatch("new-customer", [data.user_id]);
                            Livewire.dispatch("get-recent-products", [
                                data.user_id,
                            ]);
                            setCallID(data.id);
                            setBackground(data.call_background);
                            document
                                .getElementById("participant-list")
                                .addEventListener("click", () => {
                                    Livewire.dispatch("show-participant-list", [
                                        data.id,
                                    ]);
                                });
                        } else {
                            window.popinLog("room_call_expired", {
                                connectRequest: call_id,
                                mode: mode,
                            });
                            setRoomStatusText("Call Expired");
                            new FilamentNotification()
                                .title("Call Expired")
                                .danger()
                                .send();
                            setTimeout(() => {
                                Livewire.navigate("/");
                            }, 3000);
                        }
                    })
                    .catch((err) => {
                        console.error("API Error:", err);
                        Sentry.captureException(err);
                    });
            } else {
                console.error("Missing call_id or token.");
            }
        }
    }, []);

    if (!liveKitToken || !serverUrl) {
        // Optionally display a loading state
        return <div>{roomStatusText}</div>;
    }

    const onLeave = () => {
        window.popinLog("room_call_exit", {
            callID: callID,
        });
        const callDispositionForm = document.getElementById(
            "call-disposition-modal"
        );
        if (callDispositionForm) {
            Livewire.on("close-call-disposition-form", () => {
                Livewire.navigate("/");
            });

            Livewire.dispatch("show-call-disposition-form", { callID });
        } else {
            Livewire.navigate("/");
        }
    };

    return (
        <div
            data-lk-theme="default"
            style={{ height: "calc(100vh - 128px)", borderRadius: "4px" }}
        >
            <LiveKitRoom
                video={true}
                audio={true}
                token={liveKitToken} // Pass fetched token here
                serverUrl={serverUrl} // Use data.websocket as the server URL
                onDisconnected={onLeave}
                onMediaDeviceFailure={(e) => {
                    console.error(e);
                    alert(
                        "Error acquiring camera or microphone permissions. Please make sure you grant the necessary permissions in your browser and reload the tab"
                    );
                    Sentry.captureException(e);
                }}
            >
                <VideoConference background={background} />
            </LiveKitRoom>
        </div>
    );
}

export default Room;

if (document.getElementById("room")) {
    // Ensure rendering only happens in the intended element
    const Index = ReactDOM.createRoot(document.getElementById("room"));

    Index.render(
        <React.StrictMode>
            <Room />
        </React.StrictMode>
    );
}

// {
//     "status": 1,
//     "connect_request_id": 12613,
//     "name": "Anubhav",
//     "user_id": 28509,
//     "artifact": "",
//     "id": 12613,
//     "access_token": "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJzNTEiLCJqdGkiOiJzNTEiLCJleHAiOjE3MzcwMjc5NDEsIm5iZiI6MTczNzAyMDc0MSwiaWF0IjoxNzM3MDIwNzQxLCJpc3MiOiJBUElWTGpZanlGR3VUUmUiLCJuYW1lIjoiTWVyYWxkYSBKZXdlbHMiLCJ2aWRlbyI6eyJyb29tSm9pbiI6dHJ1ZSwicm9vbSI6InBvcGluX3Rlc3RfMTI2MTMtVXBKaWcifX0.cXRUzgZ_gIWzfmevrO_KElrbrGv_jraUis22OfQMZFE",
//     "seller_id": 51,
//     "user_name": "Anubhav",
//     "user_contact": "+91 9792521396",
//     "room": "popin_test_12613-UpJig",
//     "call_background": "https://store.popin.to/organization/1/call_background/677fb005ab8ec.jpg",
//     "websocket": "wss://signal.popin.cloud",
//     "created_at": 1737020738000
// }
